<template>
  <div class="container">
    <div class="user-nav">
      <el-row type="flex" justify="space-between">
        <el-col :span="8">
          <el-select
            v-model="termName"
            placeholder="当前学期"
            style="width: 195px"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in termlist"
              :key="index"
              :label="item.termName"
              :value="item.termName"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="16" style="text-align: right">
          <div class="userinfo">
            <p>{{ paramsData.userName }}</p>
            <span>学籍号：{{ paramsData.studentCode }}</span>
            <span>{{ paramsData.className }}</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="main">
      <div class="table-box">
        <h4>思想品德</h4>
        <table border="1" cellspacing="0" rules="all">
          <tr>
            <th>项目</th>
            <th>写实记录</th>
            <th>荣誉记录</th>
            <th>违纪记录</th>
            <th>总计</th>
          </tr>
          <tr>
            <td>数据条数</td>
            <td>{{ moralityForm.realyCount }}</td>
            <td>{{ moralityForm.honorCount }}</td>
            <td>{{ moralityForm.breakCount }}</td>
            <td>{{ moralityForm.sumCount }}</td>
          </tr>
          <tr>
            <td>综素成绩（分）</td>
            <td>{{ moralityForm.realyScore }}</td>
            <td>{{ moralityForm.honorScore }}</td>
            <td>{{ moralityForm.breakRemainIndexScore }}</td>
            <td>{{ moralityForm.sumScore }}</td>
          </tr>
        </table>
      </div>
      <div class="more">
        <h4>相关记录</h4>
        <el-row type="flex" justify="space-between" class="more-tit">
          <el-col :span="12">
            <h5>荣誉记录</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-button @click="handleHonorAdd" type="primary" plain
              ><i class="el-icon-circle-plus"></i> 添加荣誉</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="honorlist"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="honorName" label="荣誉名称"> </el-table-column>
          <el-table-column prop="honorType" label="荣誉类别" width="180">
          </el-table-column>
          <el-table-column prop="indexRuleLevel" label="级别" width="120">
          </el-table-column>
          <el-table-column prop="createTime" label="发布时间" width="120">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                @click="handleDetailHonor(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button
                @click="handleRemoveHonor(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>违纪记录</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-button @click="handleWeijiAdd" type="primary" plain
              ><i class="el-icon-circle-plus"></i> 添加违纪</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="weiJilist"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="breakContext" label="违纪内容">
          </el-table-column>
          <el-table-column prop="breakScore" label="扣分值" width="180">
          </el-table-column>
          <el-table-column prop="createTime" label="发布时间" width="120">
          </el-table-column>
          <el-table-column prop="breakStatus" label="状态" width="120">
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                @click="handleDetailWeiji(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button
                @click="handleRemoveWeiji(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
              <el-button
                v-if="scope.row.breakStatus == '存在'"
                @click="handleRevoke(scope.row)"
                type="text"
                size="small"
                >撤销
              </el-button>
              <el-button
                v-else
                @click="handleRecovery(scope.row)"
                type="text"
                size="small"
                >恢复
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="back">
        <el-button type="text" @click="backIndex">返回</el-button>
      </div>
    </div>

    <!-- 查看详情 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" center>
      <el-form
        ref="form"
        :model="form"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        "
      >
        <el-form-item style="border-bottom: 1px dashed #e1e3e9">
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.studentCode }}</span>
        </el-form-item>
        <!-- 违纪 -->
        <template v-if="!isHonor">
          <el-form-item label="违纪内容：">
            <span class="scontent">{{ form.breakContext }}</span>
          </el-form-item>
          <el-form-item label="扣分值：">
            <span class="scontent">{{ form.breakScore }}</span>
          </el-form-item>
          <el-form-item label="发布时间：">
            <span class="scontent">{{ form.createTime }}</span>
          </el-form-item>
          <el-form-item
            label="状态："
            style="border-bottom: 1px dashed #e1e3e9"
          >
            <span style="color: red">{{ form.breakStatus }}</span>
          </el-form-item>
        </template>
        <!-- 荣誉 -->
        <template v-if="isHonor">
          <el-form-item label="荣誉名称：">
            <span class="scontent">{{ form.honorName }}</span>
          </el-form-item>
          <el-form-item label="级别：">
            <span class="scontent">{{ form.indexRuleLevel }}</span>
          </el-form-item>
		  <el-form-item label="获奖等级：">
		    <span class="scontent">{{ form.resultLevel }}</span>
		  </el-form-item>
          <el-form-item
            label="发布时间："
            style="border-bottom: 1px dashed #e1e3e9"
          >
            <span class="scontent">{{ form.createTime }}</span>
          </el-form-item>
        </template>
        <el-form-item label="所在学期：">
          <span class="scontent">{{ form.termName }}</span>
        </el-form-item>
        <el-form-item label="颁发单位：" v-if="isHonor">
          <span class="scontent">{{ form.awardedUnit }}</span>
        </el-form-item>
        <!-- <el-form-item label="所在学校：" v-if="!isHonor">
          <span class="scontent">{{form.awardedUnit}}</span>
        </el-form-item> -->
        <el-form-item label="佐证材料：" v-if="form._showImgList">
        	<el-image
        	  v-for="(item, index) in form._imgFileList"
        		style="width: 100px; height: 100px;"
        		:preview-src-list="form._imgFileList"		
        	  :key="index"
        	  :src="item"
        	></el-image>
        </el-form-item>
        <el-form-item label="佐证材料：" v-if="form._showOtherFileList">
			<a
			v-for="(item, idx) in form._otherFileList"
			:key="item"
			target="_blank"
			:download="'附件' + idx"
			:href="item">附件{{ idx + 1 }} </a>
        </el-form-item>
        <el-form-item label="备注说明：" >
          <span class="scontent">{{ form.bz }}</span>
        </el-form-item>
		<el-form-item v-if="form.auditStatus == 2" label="驳回者：">
			<span class="scontent">{{ form.updateBy }}</span>
		</el-form-item>
		<el-form-item v-if="form.auditStatus == 2" label="驳回原因：">
			<span class="scontent">{{ form.causeDes }}</span>
		</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 添加荣誉 or 违纪 -->
    <el-dialog :title="title" :visible.sync="openAdd" width="600px" center>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        label-position="left"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        "
      >
        <el-form-item label-width="0" style="border-bottom: 1px dashed #e1e3e9">
          <span class="sname">{{ paramsData.userName }}</span>
          <span class="snumb">学籍号：{{ paramsData.studentCode }}</span>
        </el-form-item>
        <!-- 违纪 -->
        <template v-if="!isHonor">
          <el-form-item label="违纪内容：" prop="breakContext">
            <el-input v-model="form.breakContext"></el-input>
          </el-form-item>
          <el-form-item label="扣分值：" prop="breakScore">
            <el-select
              style="width: 100%"
              v-model="form.breakScore"
              placeholder="请选择扣分值"
            >
              <el-option
                v-for="index in 10"
                :key="index"
                :label="index"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在学期：" prop="termName">
            <span class="scontent">{{ form.termName }}</span>
          </el-form-item>
          <!-- <el-form-item label="所在学校：">
            <span class="scontent">信德中学</span>
          </el-form-item> -->
        </template>
        <!-- 荣誉 -->
        <template v-if="isHonor">
          <el-form-item label="荣誉名称：" prop="honorName">
            <el-input v-model="form.honorName"></el-input>
          </el-form-item>
          <el-form-item label="级别：" prop="indexRuleLevel">
            <el-select
              style="width: 100%"
              v-model="form.indexRuleLevel"
              placeholder="请选择级别"
            >
              <el-option
                v-for="(item, index) in honorTypelist"
                :key="index"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
		  <el-form-item label="获奖等级：" prop="resultLevel">
		  	<el-input style="width: 300px;" placeholder="请输入获奖等级" v-model="form.resultLevel"></el-input>
		  </el-form-item>
          <el-form-item label="颁发单位：" prop="awardedUnit">
            <el-input v-model="form.awardedUnit"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="佐证材料：">
          <el-upload
            action="nothing"
            accept=".jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt"
            :limit="3"
            :multiple="true"
            :file-list="form.fileList"
            :on-change="handleUpChange"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :auto-upload="false"
          >
            <el-button size="small" type="primary">添加附件</el-button>
            <div slot="tip" class="el-upload__tip">
              <!-- 只能上传jpg/png文件，且不超过1MB，最多三张 -->
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注说明：">
          <el-input
            v-model="form.bz"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 撤销违纪 -->
    <el-dialog title="撤销原因" :visible.sync="openRevoke" width="600px" center>
      <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
        <el-form-item prop="revokeCause" label="请选择撤销原因">
          <br />
          <el-radio-group v-model="form.revokeCause">
            <el-radio label="经核实，违纪内容不存在"
              >经核实，违纪内容不存在</el-radio
            >
            <el-radio label="经后续观察，改过态度良好，改正效果明显"
              >经后续观察，改过态度良好，改正效果明显</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="撤销作用：" prop="termName">
          <span class="scontent">被扣除的分数将恢复</span>
        </el-form-item>
        <el-form-item prop="revokeRemark" label="备注说明">
          <el-input
            v-model="form.revokeRemark"
            type="textarea"
            autocomplete="off"
            :rows="3"
            placeholder="请输入备注说明，非必填"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmitRevoke">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getHonor,
  moralityByUserId,
  getHonorDetail,
  getHonorType,
  removeHonor,
  removeHonorOneUser,
  addHonor,
  getWeiji,
  getWeijiDetail,
  getWeijiStudent,
  addWeiji,
  removeWeiji,
  revokeWeiji,
  recoveryWeiji,
} from "@/api/teacher/quality/morality";
import { getTerm } from "@/api/public/search";
export default {
  data() {
    return {
      imgSrc: this.imageSrc(),
      termName: "",
      title: "",
      open: false,
      openAdd: false,
      openRevoke: false,
      isHonor: true,
      tableData: [],
      form: {},
      rules: {
        honorName: [
          { required: true, message: "请输入荣誉名称", trigger: "blur" },
        ],
        indexRuleLevel: [
          { required: true, message: "请选择荣誉级别", trigger: "change" },
        ],
        awardedUnit: [
          { required: true, message: "请输入颁发单位", trigger: "blur" },
        ],
        studentIds: [
          { required: true, message: "请选择参与学生", trigger: "change" },
        ],

        breakContext: [
          { required: true, message: "请输入违纪内容", trigger: "blur" },
        ],
        breakScore: [
          { required: true, message: "请选择扣分值", trigger: "change" },
        ],

        revokeCause: [
          { required: true, message: "请选择撤销原因", trigger: "change" },
        ],
      },
      paramsData: {},
      moralityForm: {},
      termlist: [],
      honorlist: [],
      weiJilist: [],
      honorTypelist: [],
    };
  },
  created() {
    this.paramsData =
      this.$route.params.userName != undefined
        ? this.$route.params
        : JSON.parse(sessionStorage["params"]);
    this.termName = this.paramsData.termName;
    this.getFirst();
  },
  methods: {
    handleChange(val) {
      this.paramsData.termName = val;
      this.getFirst();
    },
    getFirst() {
      this.getMorality();
      this.getTermList();
      this.getHonorList();
      this.getWeijiList();
    },
    getMorality() {
      moralityByUserId(this.paramsData).then((res) => {
        this.moralityForm = res.data;
      });
    },
    getHonorList() {
      getHonor({
        userId: this.paramsData.userId,
        termName: this.paramsData.termName,
        pageSize: 100,
        pageNum: 1,
      }).then((res) => {
        this.honorlist = res.data.list;
      });
    },
    getWeijiList() {
      getWeijiStudent({
        userId: this.paramsData.userId,
        termName: this.paramsData.termName,
      }).then((res) => {
        this.weiJilist = res.data;
      });
    },
    backIndex() {
      this.$router.go(-1);
    },
	// 处理附件
	processAth(key) {
		let temp = key.split(",")
		let imgFileList = []
		let otherFileList = []
		// 材料按文件后缀名分流
		temp.forEach(v => {
			if (this.fileNotImg(v)) {
				otherFileList.push(this.imgSrc + v)
			} else {
				imgFileList.push(this.imgSrc + v)
			}
		})
		this.form._imgFileList = imgFileList
		this.form._otherFileList = otherFileList
		this.form._showImgList = imgFileList.length > 0
		this.form._showOtherFileList = otherFileList.length > 0
	},
    handleDetailHonor(row) {
      if (row.honorType == "个人荣誉") {
        this.title = "个人荣誉";
        this.open = true;
        this.isHonor = true;
        getHonorDetail({ honorId: row.id, honorType: "0" }).then((res) => {
          this.form = res.data;
          if(this.form.proofFile) {
            this.processAth(this.form.proofFile)
          }
        });
      } else {
        this.title = "集体荣誉";
        this.open = true;
        this.isHonor = true;
        getHonorDetail({
          userId: this.paramsData.userId,
          honorId: row.id,
          honorType: "1",
        }).then((res) => {
          this.form = res.data;
          if(this.form.proofFile) {
            this.processAth(this.form.proofFile)
          }
        });
      }
    },
    handleRemoveHonor(row) {
      this.$confirm(
        "删除该条记录后，学生对应的综素得分将被扣除，确定要删除吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (row.honorType == "个人荣誉") {
            removeHonor({ honorId: row.id, honorType: "0" }).then((res) => {
              this.$message.success("删除成功");
              this.getFirst();
            });
          } else if (row.honorType == "集体荣誉") {
            removeHonorOneUser({ honorId: row.id, userId: row.userId }).then(
              (res) => {
                this.$message.success("删除成功");
                this.getFirst();
              }
            );
          }
        })
        .catch(() => {
          return false;
        });
    },
    handleHonorAdd() {
      this.reset()
      this.title = "荣誉记录";
      this.openAdd = true;
      this.isHonor = true;
      this.form.termName = this.paramsData.termName;
      this.getHonorTypeList();
    },
    handleSubmitAdd() {
      if (this.form.file) {
        this.form.file = this.compressImg(this.form.file);
      }
      setTimeout(() => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            if (this.isHonor) {
              var formData = new FormData();
              if (this.form.file != undefined) {
                this.form.file.map((item) => {
                  formData.append("file", item);
                });
              }
              formData.append("userIds", this.paramsData.userId);
              formData.append("honorName", this.form.honorName);
              formData.append("classId", this.paramsData.classId);
              formData.append("honorType", "0");
              formData.append("indexRuleLevel", this.form.indexRuleLevel);
              formData.append("awardedUnit", this.form.awardedUnit);
			  formData.append('resultLevel', this.form.resultLevel)
              formData.append("bz", this.form.bz);
              addHonor(formData).then((res) => {
                this.$message.success("添加成功");
                this.getFirst();
                this.openAdd = false;
                this.resetForm("form");
              });
            } else {
              var formData = new FormData();
              if (this.form.file != undefined) {
                this.form.file.map((item) => {
                  formData.append("file", item);
                });
              }
              formData.append("userId", this.paramsData.userId);
              formData.append("classId", this.paramsData.classId);
              formData.append("breakContext", this.form.breakContext);
              formData.append("breakScore", this.form.breakScore);
              formData.append("bz", this.form.bz);
              addWeiji(formData).then((res) => {
                this.$message.success("添加成功");
                this.getFirst();
                this.openAdd = false;
                this.resetForm("form");
              });
            }
          } else {
            return false;
          }
        });
      }, 1000);
    },
    cancel() {
      this.openAdd = false;
      this.openRevoke = false;
      this.reset();
    },
    handleDetailWeiji(row) {
      this.reset()
      this.title = "违纪记录";
      this.open = true;
      this.isHonor = false;
      getWeijiDetail({ breakId: row.id }).then((res) => {
        this.form = res.data;
        if(this.form.proofFile) {
          this.form.proofFile = this.form.proofFile.split(",");
        }
      });
    },
    handleRemoveWeiji(row) {
      this.$confirm(
        "删除该条记录后，学生对应被扣除的分数将恢复，确定要删除吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          removeWeiji({ breakId: row.id }).then((res) => {
            this.$message.success("删除成功");
            this.getFirst();
          });
        })
        .catch(() => {
          return false;
        });
    },
    handleWeijiAdd() {
      this.title = "违纪记录";
      this.openAdd = true;
      this.isHonor = false;
      this.form.termName = this.paramsData.termName;
    },
    handleRevoke(row) {
      this.openRevoke = true;
      this.form.breakId = row.id;
    },
    handleSubmitRevoke() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          revokeWeiji({
            breakId: this.form.breakId,
            revokeCause: this.form.revokeCause,
            revokeRemark: this.form.revokeRemark,
          }).then((res) => {
            this.$message.success("撤销成功");
            this.getFirst();
            this.openRevoke = false;
            this.resetForm("form");
          });
        } else {
          return false;
        }
      });
    },
    handleRecovery(row) {
      this.$confirm(
        "恢复该条记录后，学生对应的综素得分将被扣除，确定要恢复吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          recoveryWeiji({ breakId: row.id }).then((res) => {
            this.$message.success("恢复成功");
            this.getFirst();
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 上传图片
    handleUpChange(file, fileList) {
      var _this = this;
      _this.form.file = fileList;
      if (file.size / 1024 > 5 * 1024) {
        this.$message.warning("文件大小不得超过5MB");
        this.handleRemove(file);
      }
    },
    handleRemove(file, fileList) {
      this.form.file.forEach((item, index) => {
        if (item.name === file.raw.name) {
          this.form.file.splice(index, 1);
        }
      });
    },
    handleExceed() {
      this.$message.warning("最多只能上传三个文件");
    },
    reset() {
      this.form = {
        studentIds: [],
        userName: undefined,
        studentCode: undefined,
        honorName: undefined,
        indexRuleLevel: undefined,
        awardedUnit: undefined,
        termName: undefined,
        fileList: [],
        file: undefined,
        breakContext: undefined,
        breakScore: undefined,
        bz: undefined,
		resultLevel: undefined
      };
      this.resetForm("form");
    },
    // 获取荣誉级别
    getHonorTypeList() {
      getHonorType({ type: "personHonorlevel" }).then((res) => {
        this.honorTypelist = res.data;
      });
    },
    getTermList() {
      getTerm({}).then((res) => {
        this.termlist = res.data;
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #91939A;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.el-form {
  .el-form-item {
    .el-form-item__label {
      float: none;
    }
    .el-radio-group {
      display: flex;
      flex-direction: column;
      .el-radio {
        margin-top: 10px;
      }
    }
  }
}
</style>
<style scoped>
.user-nav .el-select >>> .el-input {
  margin: 10px 12px;
}
.el-form-item {
  margin-bottom: 16px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-image {
  width: 100%;
}
</style>