import {post} from '@/utils/axios'

// 思想品德 
// 获取荣誉记录
export const getHonor = (params) => post('/shida-user-biz/TbHonor/getHonorList', params)
// 获取荣誉记录详情
export const getHonorDetail = (params) => post('/shida-user-biz/TbHonor/getHonorDetail', params)
// 删除个人荣誉记录
export const removeHonor = (params) => post('/shida-user-biz/TbHonor/deleteHonor', params)
// 删除集体荣誉记录
export const removeHonorOneUser = (params) => post('/shida-user-biz/TbHonor/deleteCollectiveHonorOneUser', params)
// 添加荣誉
export const addHonor = (params) => post('/shida-user-biz/TbHonor/saveOrUpdateHonor', params)

// 成绩汇总详情
export const moralityByUserId = (params) => post('/shida-user-biz/TbHonor/getMoralityInfo', params)
// 成绩汇总列表
export const moralityList = (params) => post('/shida-user-biz/TbHonor/getClassMoralityInfo', params)
// 获取荣誉级别
export const getHonorType = (params) => post('/shida-user-biz/TbDic/getDicByType',params)

// 违纪记录 -- 思想品德
export const getWeiji = (params) => post('/shida-user-biz/TbBreak/getClassDisciplineBreakList',params)
// 学生违纪记录 -- 思想品德
export const getWeijiStudent = (params) => post('/shida-user-biz/TbBreak/getUserDisciplineBreakList',params)
// 违纪记录详情 -- 思想品德
export const getWeijiDetail = (params) => post('/shida-user-biz/TbBreak/getDisciplineBreakDetail',params)
// 添加违纪
export const addWeiji = (params) => post('/shida-user-biz/TbBreak/saveOrUpdateDisciplineBreak',params)
// 删除违纪
export const removeWeiji = (params) => post('/shida-user-biz/TbBreak/deleteDisciplineBreak',params)
// 撤销违纪
export const revokeWeiji = (params) => post('/shida-user-biz/TbBreak/revokeDisciplineBreak',params)
// 恢复违纪
export const recoveryWeiji = (params) => post('/shida-user-biz/TbBreak/renewDisciplineBreak',params)

// 荣誉审核
export const honorAudit = params => post('/shida-user-biz/TbHonor/updateAuditStatus', params)
